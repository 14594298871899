<template>
   <!-- the-header></the-header>
   <the-footer></the-footer -->
    <div id="container">
        <img id="coming-soon-image" src="/img/palistine-stars_1.png" width="250" alt="Coming Soon Image">
        <h1 id="header">Palestine Stars - Coming Soon</h1>
    </div>
</template>
<script>
// import TheHeader from './components/nav/TheHeader.vue';
// import TheFooter from './components/nav/TheFooter.vue';
export default {
    mounted() {
        document.title = "Palestine Stars - Coming Soon";
     }
//   components: {
//     //TheHeader,
//     //TheFooter
//   }

}
</script>
<style>
/* * {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
}

body {
  margin: 0;
} */
body {
            margin: 10;
            padding: 0 20px;
            font-family: 'Arial', sans-serif;
            background-color: #f5f5f5;
            text-align: center;
        }

        #container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;
        }

        #coming-soon-image {
            max-width: 100%;
            height: auto;
            border-radius: 10px;
            margin-bottom: 20px;
        }

        #header {
            font-size: 2em;
            color: #333;
            margin-bottom: 10px;
        }

        #description {
            font-size: 1.2em;
            color: #666;
            max-width: 600px;
            margin-bottom: 40px;
        }
</style>